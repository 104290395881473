@media print {
    .pdf-container {
        display: none;
    }
}

.react-pdf__Page__canvas {
    margin: 0;
    width: 100% !important;
    height: 100% !important;
}