tr>th:first-child, tr>td:first-child {
    position: sticky;
    left: 0;
}
.table th {
    position: relative;
}
.resizer {
    position: absolute;
    top: 0;
    right: 0;
    width: 5px;
    cursor: col-resize;
    user-select: none;
}
.resizer:hover,
.resizing {
    border-right: 2px solid blue;
}

.memo-text * {
    font-size: 14px !important;
    display: inline-flex;
    align-items: center;
    height: 100% !important;
}