/* Container Styles */
.container {
    width: 100%;
    max-width: 800px;
    /* margin: 20px auto;  */
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    /* padding: 15px; Padding around the content */
  }
  
  /* Toolbar Styles */
  .toolbar {
    margin-bottom: 10px;
    background-color: black;    
    border-radius: 6px; 
    display: flex;
    justify-content: center;
  }
  
  .tool-list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
  }
  
  .tool {
    margin-right: 0;
  }
  
  .tool--btn {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    font-size: 15px;
    color: #fff;
    transition: color 0.3s ease, transform 0.2s ease;
  }

  .tool--btn:hover {
    color: #ffeb3b;
    transform: scale(1.1);
  }

  /* Editor Styles */
  .editor {
    border: 2px solid black;
    border-radius: 6px;
    padding: 5px;
    min-height: 250px;
    outline: none;
    overflow: auto;
    background-color: #ffffff; 
    font-family: 'Arial', sans-serif;     
    color: #333;
  }
  
  .editor:focus {
    border-color: #ffeb3b;
    box-shadow: 0 0 8px rgba(255, 235, 59, 0.5);
    color: #333;
  }
  
  .color-picker-wrapper {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
  }
  
  .color-picker-input {
    width: 0;
    height: 0;
    opacity: 0;
    position: absolute;
  }
  
  .color-picker-wrapper:hover {
    color: #ffeb3b;
    transform: scale(1.1);
  }

  .editor:empty:before {
    content: attr(data-placeholder);
    color: #999;
  }

  .memo-view:empty {
    content: attr(data-placeholder);
    color: #999;
  }