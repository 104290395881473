@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');

.page-image {
    display: block;
    margin: 0 auto;
    text-align: center;
}

.menu {
    list-style-type: none;
}

.menu li {
    list-style-type: none;
    position: relative;
    font-size: 15px;
    cursor: pointer;
    padding: 10px 20px;
    color: hsl(35, 35, 35);
    background-color: hsl(35, 55, 98);
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.menu li:hover {
    text-decoration: underline;
}