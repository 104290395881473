:root {
    --primary-clr: #b38add;
  }
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Poppins", sans-serif;
  }

.calendar-main {
    position: relative;
    height: 80vh; 
    margin: 0 auto;
    padding: 5px;
    color: #fff;
    display: flex;
    flex-direction: column;
    background-color: #373c4f;
    border-radius: 10px;
    overflow: hidden;
}
    
.calendar {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    color: #878895;
    border-radius: 5px;
    background-color: #fff;
    overflow: hidden;
}
    
.calendar .month {
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 50px;
    font-size: 1.2rem;
    font-weight: 500;
    text-transform: capitalize;
}
.calendar .month .prev,
.calendar .month .today,
.calendar .month .next {
    cursor: pointer;
}
.calendar .month .prev:hover,
.calendar .month .today:hover,
.calendar .month .next:hover {
    color: var(--primary-clr);
}
.calendar .month .date {
    margin-right: 180px;
}
.calendar .weekdays {
    width: 100%;
    height: 60px;
    position: sticky;
    z-index: 1;
    border-bottom: 1px solid #f5f5f5;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 1rem;
    font-weight: 500;
    text-transform: capitalize;
    background-color: #fff;
}
    
.weekdays .day {
    width: 14.28%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.calendar .days {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 20px;
}
    
.calendar .days .day {
    position: relative;
    width: 14.28%;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: var(--primary-clr);
    border: 1px solid #f5f5f5;
}

.calendar .days .day .date {
    position: absolute;
    top: 2px;
    left: 2px;
}
.calendar .days .day:nth-child(7n + 1) {
    border-left: 2px solid #f5f5f5;
}
.calendar .days .day:nth-child(7n) {
    border-right: 2px solid #f5f5f5;
}
.calendar .days .day:nth-child(-n + 7) {
    border-top: 2px solid #f5f5f5;
}
.calendar .days .day:nth-child(n + 29) {
    border-bottom: 2px solid #f5f5f5;
}


.calendar .days .prev-date,
.calendar .days .next-date {
    color: #b3b3b3;
    background-color: #f5f5f5;
}
.calendar .days .active {
    position: relative;
    /* font-size: 2rem; */
    color: #fff;
    background-color: var(--primary-clr);
    
}
.calendar .days .active::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* box-shadow: 0 0 10px 2px var(--primary-clr); */
}

.event-item {
    margin-bottom: 1px;
    padding: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
@media screen and (max-width: 1000px) {
    .calendar-main {
        flex-direction: column;
        height: auto;
    }

    .calendar {
        height: auto;
    }

    .calendar .days .day {
        height: 80px;
    }
}

@media screen and (max-width: 500px) {
    .calendar .month {
        height: 75px;
    }

    .calendar .weekdays {
        height: 50px;
    }

    .calendar .days .day {
        height: 40px;
        font-size: 0.8rem;
    }
}


/* Keyframes for blinking and fade-out effect */
@keyframes blinker {
    25% {
      opacity: 0.5;
      /* background-color: #707070; */
    }
    50% {
      opacity: 0;
      /* background-color: #ffffff; */
    }
    75% {
      opacity: 0.5;
      /* background-color: #707070; */
    }
    100% {
      opacity: 1;
      /* background-color: #ffffff; */
    }
  }
  
  /* Blinking effect style */
  .blink_me {
    animation: blinker 1s linear 3;
  }

  
.event-color-indicators {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    z-index: 1;
}
.event-color-indicator-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    color: black;
}